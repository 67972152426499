.message-screenY-div {
    .main-heading {
        margin-bottom: 40px;

        h3 {
            font-size: 32px;
            line-height: 40px;
            font-weight: 400;
        }

        a {
            color: inherit;
            text-decoration: none;
        }
    }

    .messaging-screen-main {
        background-color: white;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, .06);
        border-radius: 4px;
        margin-bottom: 1rem;

        .top-header {
            padding: 22px 30px;
            border-bottom: 1px solid #eaeaea;

            h4 {
                font-size: 16px;
                color: #333;
                margin: 0;
                font-weight: 600;
            }

            .inner-desc {
                margin-left: 8px;
                font-size: 14px;
                padding: 8px 12px;
                font-weight: 500;
                display: inline-block;
                background-color: #f2f2f2;
                color: #888;
                line-height: 12px;
                border-radius: 50px;
            }
        }

        .chatting-div {
            padding: 30px 30px calc(30px - 1.2rem);
            height: 72vh;
            overflow-y: overlay;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
                background: rgb(169, 169, 169);
                border-radius: 50px;
            }

            .sender,
            .receiver {
                margin-bottom: 1.2rem;
            }

            .avatar-img {
                width: 50px;
                height: 50px;
                margin-top: 3px;
                border-radius: 50px;
            }

            p {
                color: #dd3333;
                background-color: rgba(221, 51, 51, 0.05);
                width: 100%;
                max-width: 60rem;
                padding: 15px 20px;
            }
        }

        .write-text-div {
            padding: 14px;
            border-top: 1px solid #eaeaea;

            i.fa-smile-o {
                position: absolute;
                bottom: .7rem;
                left: .5rem;
                font-size: 18px;
                cursor: pointer;
            }

            .emoji-picker-react{
                position: absolute;
                bottom: 60px;
            }

            textarea {
                border-radius: 8px;
                padding-left: 2rem;
                box-shadow: none;
                height: 2.5rem;

                &::-webkit-scrollbar{
                    display: none;
                }
            }

            .fa-paperclip{
                font-size: 20px;
                margin: 0 12px 0 12px;
                cursor: pointer;
            }

            .fa-paper-plane{
                font-size: 18px;
                color: blue;
            }
        }
    }
}



.c-sidebar-nav-link.active{
    color: white !important;
    background-color: #321fdb !important;
  }